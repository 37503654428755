import axios, { AxiosInstance } from "axios";

import ISession from "../../Contexts/Session/ISession";

export type IResponseType = {
  statusCode: number;
  data: any;
  included?: any;
};

export type IRequestProps = {
  method: "GET" | "POST" | "PATCH" | "PUT" | "DELETE";
  url: string;
  session: ISession;
  params?: object;
  data?: object;
  headers?: object;
};
interface IApiError extends Error {
  statusCode?: number;
  data?: any;
}

const createApiResponse = (statusCode: number, data: any, included?: any): IResponseType => ({
  statusCode: statusCode,
  data: data,
  included: included
});

const HttpRequest = async (props: IRequestProps): Promise<IResponseType> => {
  const headers = {
    Accept: "application/vnd.api+json",
    "Content-Type": "application/vnd.api+json",
    NACSCode: props.session.nationalPracticeCode,
    SessionToken: props.session.sessionToken
  };

  if (props.url.includes("reminders") || props.url.includes("widgets") || props.url.includes("footerItems")) {
    props.headers = { Modules: props.session.modules.toString() };
  }

  if (props.url.includes("notifications") && props.method === "GET") {
    props.headers = {
      Modules: props.session.modules.toString(),
      EnabledNotifications: props.session.enabledNotifications.toString()
    };
  }

  const axiosInstance: AxiosInstance = axios.create({
    baseURL: props.session.proscriptConnectRestApiServiceUrl,
    headers: headers,
    timeout: 30000
  });

  try {
    if (props.method === "POST" && props.data === undefined) {
      const error: IApiError = new Error("POST request must have data");
      error.statusCode = 500;
      error.data = "POST request must have data";
      return Promise.reject(error);
    }

    const response = await axiosInstance.request({
      method: props.method,
      url: props.url,
      params: props.params ?? {},
      data: props.data ?? {},
      headers: props.headers ?? {}
    });

    return Promise.resolve(createApiResponse(response.status, response.data.data, response.data?.included));
  } catch (error) {
    const apiError: IApiError = new Error(error.message);
    if (error.response) {
      apiError.statusCode = error.response.status;
      apiError.data = error.response.data.errors;
    } else {
      apiError.statusCode = 500;
      apiError.data = error.message;
    }
    return Promise.reject(apiError);
  }
};

export default HttpRequest;
