enum WidgetOrderEnum {
  "Scripts",
  "Reminders",
  "Handover",
  "Pending",
  "Awaiting Clinical Check",
  "RMS",
  "Notification"
}

export default WidgetOrderEnum;
