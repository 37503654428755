import { useContext, useEffect } from "react";

import { Collection } from "@emisgroup/ui-card";
import { ReminderCard } from "../Widgets/ReminderCard/ReminderCard";
import useVerifyWidget from "../../CustomHooks/UseVerifyWidget";
import { EmptyWidget } from "../EmptyWidget/EmptyWidget";
import AuthenticateLoad from "../Authentication/AuthenticateLoad";
import { WidgetContext, WidgetContextValue } from "../../Contexts/Widget/WidgetContext";
import ScriptCard from "../Widgets/ScriptCard/Index";
import WidgetEnum from "../../Enum/WidgetEnum";
import HandoverCard from "../Widgets/HandoverCard/Index";
import AwaitingClinicalCheckCard from "../Widgets/AwaitingClinicalCheckCard/AwaitingClinicalCheckCard";
import RmsCard from "../Widgets/RmsCard/Index";
import PendingCard from "../Widgets/PendingCard/Index";
import NotificationCard from "../Widgets/NotificationCard/NotificationCard";

import "./WidgetCollection.scss";

export const WidgetCollection = (props: { setWidgetEnable: (arg: boolean) => void }) => {
  const { widget } = useContext<WidgetContextValue>(WidgetContext);
  const widgetEnable = useVerifyWidget();
  const showWidget = widgetEnable?.some((item) => item.enabled === true);

  const widgets = widgetEnable?.map((widget) => {
    switch (widget.name) {
      case WidgetEnum.SCRIPTS:
        return widget.enabled && <ScriptCard key={widget.name} refreshIntervalSecond={widget.refreshIntervalSecond} />;

      case WidgetEnum.REMINDERS:
        return (
          widget.enabled && <ReminderCard key={widget.name} refreshIntervalSecond={widget.refreshIntervalSecond} />
        );

      case WidgetEnum.HANDOVER:
        return (
          widget.enabled && <HandoverCard key={widget.name} refreshIntervalSecond={widget.refreshIntervalSecond} />
        );

      case WidgetEnum.AWAITING_CLINICAL_CHECK:
        return (
          widget.enabled && (
            <AwaitingClinicalCheckCard key={widget.name} refreshIntervalSecond={widget.refreshIntervalSecond} />
          )
        );

      case WidgetEnum.RMS:
        return widget.enabled && <RmsCard key={widget.name} refreshIntervalSecond={widget.refreshIntervalSecond} />;

      case WidgetEnum.PENDING:
        return widget.enabled && <PendingCard key={widget.name} refreshIntervalSecond={widget.refreshIntervalSecond} />;

      case WidgetEnum.NOTIFICATIONS:
        return widget.enabled && <NotificationCard key={widget.name} />;

      default:
        break;
    }
  });

  useEffect(() => {
    props.setWidgetEnable(showWidget);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widgetEnable]);

  return (
    <>
      {widget === undefined ? (
        <div className="widget-loader">
          <AuthenticateLoad />
        </div>
      ) : (
        <div
          data-dd-action-name="collection-container"
          className="collection-container"
          data-testid="collection-container"
        >
          {!showWidget ? <EmptyWidget /> : <Collection className="card-collection">{widgets}</Collection>}
        </div>
      )}
    </>
  );
};
