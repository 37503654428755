import { useContext } from "react";

import { SessionContext, SessionContextValue } from "../Contexts/Session/SessionContext";
import { UserDetailsContext, UserDetailsContextValue } from "../Contexts/UserDetailsContext/UserDetailsContext";
import ISession from "../Contexts/Session/ISession";
import IUserDetails from "../Contexts/UserDetailsContext/IUserDetails";
import { AuthorizeWidget } from "../Components/AuthorizeWidget/AuthorizeWidget";
import ActivityEnum from "../Enum/ActivityEnum";
import ProScriptConnectModule from "../Enum/ProScriptConnectModule";
import { INotificationData } from "../Components/Widgets/NotificationCard/NotificationCard";
import NotificationTypeEnum from "../Enum/NotificationTypeEnum";

export const useValidateNotificationItems = (notificationItems: INotificationData[]) => {
  const { session } = useContext<SessionContextValue>(SessionContext);
  const { userDetails } = useContext<UserDetailsContextValue>(UserDetailsContext);
  let enabledNotificationItems = ValidateNotificationItems(session, userDetails, notificationItems);
  let filteredNotificationItems = enabledNotificationItems?.filter((i: INotificationData) => typeof i !== "undefined");
  return filteredNotificationItems;
};

const ValidateNotificationItems = (
  session: ISession,
  userDetails: IUserDetails,
  notificationItems?: INotificationData[]
) => {
  const notificationItemsCheck = (item: INotificationData, activityId: number, moduleName?: ProScriptConnectModule) => {
    if (
      AuthorizeWidget({
        validationDetails: {
          activityId: activityId,
          moduleName: [moduleName]
        },
        contextDetails: { userDetails, session }
      })
    )
      return item;
  };

  return notificationItems?.map((item: INotificationData) => {
    switch (item?.typeName) {
      case NotificationTypeEnum.EMERGENCY_SUPPLY_OVERDUE:
        item = notificationItemsCheck(
          item,
          ActivityEnum.EMERGENCY_SUPPLY_OVERDUE,
          ProScriptConnectModule.OVERDUE_SCRIPTS
        );
        return item;

      case NotificationTypeEnum.OWING:
        item = notificationItemsCheck(item, ActivityEnum.OWINGS, ProScriptConnectModule.OWINGS);
        return item;
    }
  });
};
