export enum NotificationActionType {
  ADD = 1,
  GET = 2,
  GOTO = 3,
  SNOOZE = 4,
  DELETE = 5,
  SNOOZEALL = 6,
  DELETEALL = 7,
  UNSNOOZE = 8,
  ACTIONED = 9
}
