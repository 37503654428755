import { INotificationPayloadType } from "../../Components/Widgets/NotificationCard/INotificationPaylaodType";

export const DispatchSystemtrayNotificationEvent = (notificationPayloaData: INotificationPayloadType) => {
  const systemtrayNotificationEvent = new CustomEvent("SystemtrayNotificationEvent", {
    detail: {
      type: "SystemtrayNotification",
      data: notificationPayloaData
    }
  });
  document.dispatchEvent(systemtrayNotificationEvent);
};
